.logo {
  width: 150px;
}

.layout-navbar-fixed
  .layout-wrapper:not(.layout-horizontal)
  .layout-page:before {
  content: "";
  width: 100%;
  height: 0.75rem;
  position: fixed;
  top: 0px;
  z-index: 10;
}
.bg-menu-theme .menu-header {
  position: relative;
}
.bg-menu-theme .menu-header:before {
  content: "";
  position: absolute;
  left: 0;
  top: 1.1875rem;
  width: 1rem;
  height: 1px;
  transition: all 0.3s ease-in-out;
}
[dir="rtl"] .bg-menu-theme .menu-header:before {
  right: 0;
}
.layout-wrapper:not(.layout-horizontal)
  .bg-menu-theme
  .menu-inner
  .menu-item
  .menu-link {
  border-radius: 0.375rem;
}
.layout-horizontal .bg-menu-theme .menu-inner > .menu-item > .menu-link {
  border-radius: 0.375rem;
}
@media (min-width: 1200px) {
  .layout-horizontal .bg-menu-theme .menu-inner > .menu-item {
    margin: 0.565rem 0;
  }
  .layout-horizontal .bg-menu-theme .menu-inner > .menu-item:not(:first-child) {
    margin-left: 0.0625rem;
  }
  .layout-horizontal .bg-menu-theme .menu-inner > .menu-item:not(:last-child) {
    margin-right: 0.0625rem;
  }
  .layout-horizontal .bg-menu-theme .menu-inner > .menu-item .menu-sub {
    box-shadow: 0 0.25rem 1rem rgba(161, 172, 184, 0.45);
  }
}
.layout-wrapper:not(.layout-horizontal)
  .bg-menu-theme
  .menu-inner
  > .menu-item.active:before {
  content: "";
  position: absolute;
  right: 0;
  width: 0.25rem;
  height: 2.5rem;
  border-radius: 0.375rem 0 0 0.375rem;
}
[dir="rtl"]
  .layout-wrapper:not(.layout-horizontal)
  .bg-menu-theme
  .menu-inner
  > .menu-item.active:before {
  left: 0;
  right: inherit;
  border-radius: 0 0.375rem 0.375rem 0;
}
.bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
  content: "";
  position: absolute;
  left: 1.4375rem;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
}
[dir="rtl"] .bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
  right: 1.4375rem;
  left: inherit;
}
.layout-horizontal .bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
  left: 1.3rem;
}
[dir="rtl"]
  .layout-horizontal
  .bg-menu-theme
  .menu-sub
  > .menu-item
  > .menu-link:before {
  right: 1.3rem;
  left: inherit;
}
.bg-menu-theme
  .menu-horizontal-wrapper
  > .menu-inner
  > .menu-item
  > .menu-sub
  > .menu-item
  > .menu-link:before {
  display: none;
}
.bg-menu-theme
  .menu-sub
  > .menu-item.active
  > .menu-link:not(.menu-toggle):before {
  left: 1.1875rem;
  width: 0.875rem;
  height: 0.875rem;
}
[dir="rtl"]
  .bg-menu-theme
  .menu-sub
  > .menu-item.active
  > .menu-link:not(.menu-toggle):before {
  right: 1.1875rem;
  left: inherit;
}
.layout-horizontal
  .bg-menu-theme
  .menu-sub
  > .menu-item.active
  > .menu-link:not(.menu-toggle):before {
  left: 1.1rem;
}
[dir="rtl"]
  .layout-horizontal
  .bg-menu-theme
  .menu-sub
  > .menu-item.active
  > .menu-link:not(.menu-toggle):before {
  right: 1.1rem;
  left: inherit;
}
.layout-menu-hover .layout-menu {
  box-shadow: 0 0.625rem 1.25rem rgba(161, 172, 184, 0.5);
  transition: all 0.3s ease-in-out;
}
.app-brand .layout-menu-toggle {
  position: absolute;
  left: 15rem;
  border-radius: 50%;
}
[dir="rtl"] .app-brand .layout-menu-toggle {
  left: inherit;
  right: 15rem;
}
.app-brand .layout-menu-toggle i {
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.3s ease-in-out;
}
[dir="rtl"] .app-brand .layout-menu-toggle i {
  transform: scaleX(-1);
}
@media (max-width: 1199.98px) {
  .app-brand .layout-menu-toggle {
    display: none;
  }
  .layout-menu-expanded .app-brand .layout-menu-toggle {
    display: block;
  }
}
body {
  background: #f4f5fb;
}
.bg-body {
  background: #f4f5fb !important;
}
.text-primary {
  color: #076fb0 !important;
}
.text-body[href]:hover {
  color: #5f61e6 !important;
}
.bg-primary {
  background-color: #076fb0 !important;
}
a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #6467f2 !important;
}
.dropdown-notifications-item:not(.mark-as-read)
  .dropdown-notifications-read
  span {
  background-color: #076fb0;
}
.bg-label-primary {
  background-color: #e7e7ff !important;
  color: #076fb0 !important;
}
.border-label-primary {
  border: 3px solid #c3c4ff !important;
}
.border-light-primary {
  border: 3px solid rgba(105, 108, 255, 0.08);
}
.page-item.active .page-link,
.page-item.active .page-link:hover,
.page-item.active .page-link:focus,
.pagination li.active > a:not(.page-link),
.pagination li.active > a:not(.page-link):hover,
.pagination li.active > a:not(.page-link):focus {
  border-color: #076fb0;
  background-color: #076fb0;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(7 111 176, 0.4);
}
.progress-bar {
  background-color: #076fb0;
  color: #fff;
  box-shadow: 0 2px 4px 0 rgba(7 111 176, 0.4);
}
.modal-onboarding .carousel-indicators [data-bs-target] {
  background-color: #076fb0;
}
.carousel-control-prev,
.carousel-control-next {
  color: #076fb0;
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #076fb0;
}
.list-group-item-primary {
  background-color: #e1e2ff;
  color: #076fb0 !important;
}
a.list-group-item-primary,
button.list-group-item-primary {
  color: #076fb0;
}
a.list-group-item-primary:hover,
a.list-group-item-primary:focus,
button.list-group-item-primary:hover,
button.list-group-item-primary:focus {
  background-color: #d6d7f2;
  color: #076fb0;
}
a.list-group-item-primary.active,
button.list-group-item-primary.active {
  border-color: #076fb0;
  background-color: #076fb0;
  color: #076fb0;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  border-color: #076fb0;
  background-color: #076fb0;
}
.list-group.list-group-timeline .list-group-timeline-primary:before {
  background-color: #076fb0;
}
.alert-primary {
  background-color: #e7e7ff;
  border-color: #d2d3ff;
  color: #076fb0;
}
.alert-primary .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='ðŸŽ¨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23696cff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23696cff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.alert-primary .alert-link {
  color: #076fb0;
}
.card .alert-primary hr {
  background-color: #076fb0 !important;
}
.accordion.accordion-header-primary .accordion-item.active .accordion-button {
  color: #076fb0 !important;
}
.tooltip-primary .tooltip-inner,
.tooltip-primary > .tooltip .tooltip-inner,
.ngb-tooltip-primary + ngb-tooltip-window .tooltip-inner {
  background: #076fb0;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(7 111 176, 0.4);
}
.tooltip-primary.bs-tooltip-top .tooltip-arrow::before,
.tooltip-primary > .tooltip.bs-tooltip-top .tooltip-arrow::before,
.ngb-tooltip-primary
  + ngb-tooltip-window.bs-tooltip-top
  .tooltip-arrow::before {
  border-top-color: #076fb0;
}
.tooltip-primary.bs-tooltip-start .tooltip-arrow::before,
.tooltip-primary > .tooltip.bs-tooltip-start .tooltip-arrow::before,
.ngb-tooltip-primary
  + ngb-tooltip-window.bs-tooltip-start
  .tooltip-arrow::before {
  border-left-color: #076fb0;
}
[dir="rtl"] .tooltip-primary.bs-tooltip-start .tooltip-arrow::before,
[dir="rtl"] .tooltip-primary > .tooltip.bs-tooltip-start .tooltip-arrow::before,
[dir="rtl"]
  .ngb-tooltip-primary
  + ngb-tooltip-window.bs-tooltip-start
  .tooltip-arrow::before {
  border-right-color: #076fb0;
}
.tooltip-primary.bs-tooltip-bottom .tooltip-arrow::before,
.tooltip-primary > .tooltip.bs-tooltip-bottom .tooltip-arrow::before,
.ngb-tooltip-primary
  + ngb-tooltip-window.bs-tooltip-bottom
  .tooltip-arrow::before {
  border-bottom-color: #076fb0;
}
.tooltip-primary.bs-tooltip-end .tooltip-arrow::before,
.tooltip-primary > .tooltip.bs-tooltip-end .tooltip-arrow::before,
.ngb-tooltip-primary
  + ngb-tooltip-window.bs-tooltip-end
  .tooltip-arrow::before {
  border-right-color: #076fb0;
}
[dir="rtl"] .tooltip-primary.bs-tooltip-end .tooltip-arrow::before,
[dir="rtl"] .tooltip-primary > .tooltip.bs-tooltip-end .tooltip-arrow::before,
[dir="rtl"]
  .ngb-tooltip-primary
  + ngb-tooltip-window.bs-tooltip-end
  .tooltip-arrow::before {
  border-left-color: #076fb0;
}
.popover.popover-primary,
.popover .popover-primary > .popover,
.popover .ngb-popover-primary + ngb-popover-window {
  border-color: transparent;
  background: #076fb0;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(7 111 176, 0.4);
}
.popover.popover-primary .popover-header,
.popover .popover-primary > .popover .popover-header,
.popover .ngb-popover-primary + ngb-popover-window .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
  background: transparent;
  color: #fff;
}
.popover.popover-primary .popover-body,
.popover .popover-primary > .popover .popover-body,
.popover .ngb-popover-primary + ngb-popover-window .popover-body {
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
}
.popover.popover-primary > .popover-arrow::before,
.popover .popover-primary > .popover > .popover-arrow::before,
.popover .ngb-popover-primary + ngb-popover-window > .popover-arrow::before {
  border-color: transparent;
}
.popover.popover-primary.bs-popover-top > .popover-arrow::after,
.popover .popover-primary > .popover.bs-popover-top > .popover-arrow::after,
.popover
  .ngb-popover-primary
  + ngb-popover-window.bs-popover-top
  > .popover-arrow::after {
  border-top-color: #076fb0;
}
.popover.popover-primary.bs-popover-end > .popover-arrow::after,
.popover .popover-primary > .popover.bs-popover-end > .popover-arrow::after,
.popover
  .ngb-popover-primary
  + ngb-popover-window.bs-popover-end
  > .popover-arrow::after {
  border-right-color: #076fb0;
}
[dir="rtl"] .popover.popover-primary.bs-popover-end > .popover-arrow::after,
[dir="rtl"]
  .popover
  .popover-primary
  > .popover.bs-popover-end
  > .popover-arrow::after,
[dir="rtl"]
  .popover
  .ngb-popover-primary
  + ngb-popover-window.bs-popover-end
  > .popover-arrow::after {
  border-left-color: #076fb0;
}
.popover.popover-primary.bs-popover-bottom > .popover-arrow::after,
.popover .popover-primary > .popover.bs-popover-bottom > .popover-arrow::after,
.popover
  .ngb-popover-primary
  + ngb-popover-window.bs-popover-bottom
  > .popover-arrow::after {
  border-bottom-color: #076fb0 !important;
}
.popover.popover-primary.bs-popover-start > .popover-arrow::after,
.popover .popover-primary > .popover.bs-popover-start > .popover-arrow::after,
.popover
  .ngb-popover-primary
  + ngb-popover-window.bs-popover-start
  > .popover-arrow::after {
  border-left-color: #076fb0;
}
[dir="rtl"] .popover.popover-primary.bs-popover-start > .popover-arrow::after,
[dir="rtl"]
  .popover
  .popover-primary
  > .popover.bs-popover-start
  > .popover-arrow::after,
[dir="rtl"]
  .popover
  .ngb-popover-primary
  + ngb-popover-window.bs-popover-start
  > .popover-arrow::after {
  border-right-color: #076fb0;
}
.popover.popover-primary.bs-popover-bottom .popover-header::before,
.popover .popover-primary > .popover.bs-popover-bottom .popover-header::before,
.popover
  .ngb-popover-primary
  + ngb-popover-window.bs-popover-bottom
  .popover-header::before {
  border-bottom: 1px solid transparent;
}
.table-primary {
  --bs-table-bg: #e1e2ff;
  --bs-table-striped-bg: #dcdefb;
  --bs-table-striped-color: #435971;
  --bs-table-active-bg: #d1d4f1;
  --bs-table-active-color: #435971;
  --bs-table-hover-bg: #d8daf6;
  --bs-table-hover-color: #435971;
  color: #435971;
  border-color: #d1d4f1;
}
.table-primary th {
  border-bottom-color: inherit !important;
}
.table-primary .btn-icon {
  color: #435971;
}
.btn-primary {
  color: #fff;
  background-color: #076fb0;
  border-color: #076fb0;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(7 111 176, 0.4);
}
.btn-primary:hover {
  color: #fff;
  background-color: #0967a2;
  border-color: #076fb0;
  transform: translateY(-1px);
}
.btn-check:focus + .btn-primary,
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #0967a2;
  border-color: #076fb0;
  transform: translateY(0);
  box-shadow: none;
}
.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #595cd9;
  border-color: #595cd9;
}
.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-primary.disabled,
.btn-primary:disabled {
  box-shadow: none;
}
.btn-label-primary {
  color: #076fb0;
  border-color: transparent;
  background: #e7e7ff;
}
.btn-label-primary:hover {
  border-color: transparent;
  background: #5f61e6;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(7 111 176, 0.4);
  transform: translateY(-1px);
}
.btn-label-primary:focus,
.btn-label-primary.focus {
  color: #fff;
  background: #5f61e6;
  box-shadow: none !important;
  transform: translateY(0);
}
.btn-label-primary:active,
.btn-label-primary.active,
.show > .btn-label-primary.dropdown-toggle {
  color: #fff;
  background-color: #595cd9;
  border-color: transparent;
}
.btn-label-primary:active:focus,
.btn-label-primary.active:focus,
.show > .btn-label-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-label-primary.disabled,
.btn-label-primary:disabled {
  box-shadow: none;
}
.btn-outline-primary {
  color: #076fb0;
  border-color: #076fb0;
  background: transparent;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0967a2;
  border-color: #076fb0;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(7 111 176, 0.4);
  transform: translateY(-1px);
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  color: #fff;
  background-color: #0967a2;
  border-color: #076fb0;
  box-shadow: none;
  transform: translateY(0);
}
.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #595cd9;
  border-color: #595cd9;
}
.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: none;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  box-shadow: none;
}
.btn-outline-primary .badge {
  background: #076fb0;
  border-color: #076fb0;
  color: #fff;
}
.btn-outline-primary:hover .badge,
.btn-outline-primary:focus:hover .badge,
.btn-outline-primary:active .badge,
.btn-outline-primary.active .badge,
.show > .btn-outline-primary.dropdown-toggle .badge {
  background: #fff;
  border-color: #fff;
  color: #076fb0;
}
.dropdown-item:not(.disabled).active,
.dropdown-item:not(.disabled):active {
  background-color: rgba(105, 108, 255, 0.08);
  color: #076fb0 !important;
}
.dropdown-menu > li:not(.disabled) > a:not(.dropdown-item):active,
.dropdown-menu > li.active:not(.disabled) > a:not(.dropdown-item) {
  background-color: rgba(105, 108, 255, 0.08);
  color: #076fb0 !important;
}
.nav .nav-link:hover,
.nav .nav-link:focus {
  color: #5f61e6;
}
.nav-pills .nav-link.active,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-link.active:focus {
  background-color: #076fb0;
  color: #fff;
  box-shadow: 0 2px 4px 0 rgba(7 111 176, 0.4);
}
.form-control:focus,
.form-select:focus {
  border-color: #076fb0;
}
.input-group:focus-within .form-control,
.input-group:focus-within .input-group-text {
  border-color: #076fb0;
}
.form-check-input:focus {
  border-color: #076fb0;
  box-shadow: 0 2px 4px 0 rgba(7 111 176, 0.4);
}
.form-check-input:disabled {
  background-color: #eceef1;
}
.form-check-input:checked,
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #076fb0;
  border-color: #076fb0;
  box-shadow: 0 2px 4px 0 rgba(7 111 176, 0.4);
}
.custom-option.checked {
  border: 1px solid #076fb0;
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23696cff'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-control:focus ~ .form-label {
  border-color: #076fb0;
}
.form-control:focus ~ .form-label::after {
  border-color: inherit;
}
.switch-input:checked ~ .switch-toggle-slider {
  background: #076fb0;
  color: #fff;
}
.switch-input:active ~ .switch-toggle-slider {
  box-shadow: none;
}
.switch-input:focus ~ .switch-toggle-slider {
  border: 1px solid #076fb0;
  box-shadow: 0 0 0.25rem 0.05rem rgba(105, 108, 255, 0.1);
}
.switch-primary.switch .switch-input:checked ~ .switch-toggle-slider {
  background: #076fb0;
  color: #fff;
}
.switch-primary.switch .switch-input:active ~ .switch-toggle-slider {
  box-shadow: none;
}
.switch-primary.switch .switch-input:focus ~ .switch-toggle-slider {
  border: 1px solid #076fb0;
  box-shadow: 0 0 0.25rem 0.05rem rgba(105, 108, 255, 0.1);
}
.timeline .timeline-item-primary .timeline-event {
  background-color: rgba(105, 108, 255, 0.1);
}
.timeline .timeline-item-primary .timeline-event:before {
  border-left-color: rgba(105, 108, 255, 0.1) !important;
  border-right-color: rgba(105, 108, 255, 0.1) !important;
}
.timeline .timeline-point-primary {
  background-color: #076fb0 !important;
  box-shadow: 0 0 0 0.1875rem rgba(105, 108, 255, 0.16);
}
.timeline .timeline-indicator-primary {
  border-color: #076fb0 !important;
}
.timeline .timeline-indicator-primary i {
  color: #076fb0 !important;
}
.divider.divider-primary .divider-text:before,
.divider.divider-primary .divider-text:after {
  border-color: #076fb0;
}
.navbar.bg-primary {
  background-color: #076fb0 !important;
  color: #e0e1ff;
}
.navbar.bg-primary .navbar-brand,
.navbar.bg-primary .navbar-brand a {
  color: #fff;
}
.navbar.bg-primary .navbar-brand:hover,
.navbar.bg-primary .navbar-brand:focus,
.navbar.bg-primary .navbar-brand a:hover,
.navbar.bg-primary .navbar-brand a:focus {
  color: #fff;
}
.navbar.bg-primary .navbar-search-wrapper .navbar-search-icon,
.navbar.bg-primary .navbar-search-wrapper .search-input {
  color: #e0e1ff;
}
.navbar.bg-primary .search-input-wrapper .search-input,
.navbar.bg-primary .search-input-wrapper .search-toggler {
  background-color: #076fb0 !important;
  color: #e0e1ff;
}
.navbar.bg-primary .navbar-nav > .nav-link,
.navbar.bg-primary .navbar-nav > .nav-item > .nav-link,
.navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link {
  color: #e0e1ff;
}
.navbar.bg-primary .navbar-nav > .nav-link:hover,
.navbar.bg-primary .navbar-nav > .nav-link:focus,
.navbar.bg-primary .navbar-nav > .nav-item > .nav-link:hover,
.navbar.bg-primary .navbar-nav > .nav-item > .nav-link:focus,
.navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link:hover,
.navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #fff;
}
.navbar.bg-primary .navbar-nav > .nav-link.disabled,
.navbar.bg-primary .navbar-nav > .nav-item > .nav-link.disabled,
.navbar.bg-primary .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #b0b2ff !important;
}
.navbar.bg-primary .navbar-nav .show > .nav-link,
.navbar.bg-primary .navbar-nav .active > .nav-link,
.navbar.bg-primary .navbar-nav .nav-link.show,
.navbar.bg-primary .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar.bg-primary .navbar-toggler {
  color: #e0e1ff;
  border-color: rgba(255, 255, 255, 0.15);
}
.navbar.bg-primary .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='14px' height='11px' viewBox='0 0 14 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M0,0 L14,0 L14,1.75 L0,1.75 L0,0 Z M0,4.375 L14,4.375 L14,6.125 L0,6.125 L0,4.375 Z M0,8.75 L14,8.75 L14,10.5 L0,10.5 L0,8.75 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='ðŸ’Ž-UI-Elements' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='12)-Navbar' transform='translate(-1174.000000, -1290.000000)'%3E%3Cg id='Group' transform='translate(1174.000000, 1288.000000)'%3E%3Cg id='Icon-Color' transform='translate(0.000000, 2.000000)'%3E%3Cuse fill='rgba(255, 255, 255, 0.8)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.1' fill='rgba(255, 255, 255, 0.8)' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.navbar.bg-primary .navbar-text {
  color: #e0e1ff;
}
.navbar.bg-primary .navbar-text a {
  color: #fff;
}
.navbar.bg-primary .navbar-text a:hover,
.navbar.bg-primary .navbar-text a:focus {
  color: #fff;
}
.navbar.bg-primary hr {
  border-color: rgba(255, 255, 255, 0.15);
}
.menu.bg-primary {
  background-color: #076fb0 !important;
  color: #e0e1ff;
}
.menu.bg-primary .menu-link,
.menu.bg-primary .menu-horizontal-prev,
.menu.bg-primary .menu-horizontal-next {
  color: #e0e1ff;
}
.menu.bg-primary .menu-link:hover,
.menu.bg-primary .menu-link:focus,
.menu.bg-primary .menu-horizontal-prev:hover,
.menu.bg-primary .menu-horizontal-prev:focus,
.menu.bg-primary .menu-horizontal-next:hover,
.menu.bg-primary .menu-horizontal-next:focus {
  color: #fff;
}
.menu.bg-primary .menu-link.active,
.menu.bg-primary .menu-horizontal-prev.active,
.menu.bg-primary .menu-horizontal-next.active {
  color: #fff;
}
.menu.bg-primary .menu-item.disabled .menu-link,
.menu.bg-primary .menu-horizontal-prev.disabled,
.menu.bg-primary .menu-horizontal-next.disabled {
  color: #b0b2ff !important;
}
.menu.bg-primary .menu-item.open:not(.menu-item-closing) > .menu-toggle,
.menu.bg-primary .menu-item.active > .menu-link {
  color: #fff;
}
.menu.bg-primary .menu-item.active > .menu-link:not(.menu-toggle) {
  background-color: #6d70ff;
}
.menu.bg-primary.menu-horizontal
  .menu-sub
  > .menu-item.active
  > .menu-link:not(.menu-toggle) {
  background-color: #7174ff;
}
.menu.bg-primary.menu-horizontal
  .menu-inner
  .menu-item:not(.menu-item-closing)
  > .menu-sub,
.menu.bg-primary.menu-horizontal .menu-inner .menu-item.open > .menu-toggle {
  background: #6d70ff;
}
.menu.bg-primary
  .menu-inner
  > .menu-item.menu-item-closing
  .menu-item.open
  .menu-sub,
.menu.bg-primary
  .menu-inner
  > .menu-item.menu-item-closing
  .menu-item.open
  .menu-toggle {
  background: transparent;
  color: #e0e1ff;
}
.menu.bg-primary .menu-inner-shadow {
  background: linear-gradient(
    #076fb0 41%,
    rgba(105, 108, 255, 0.11) 95%,
    rgba(105, 108, 255, 0)
  );
}
.menu.bg-primary .menu-text {
  color: #fff;
}
.menu.bg-primary .menu-header {
  color: #c2c4ff;
}
.menu.bg-primary hr,
.menu.bg-primary .menu-divider,
.menu.bg-primary .menu-inner > .menu-item.open > .menu-sub::before {
  border-color: rgba(255, 255, 255, 0.15) !important;
}
.menu.bg-primary .menu-inner > .menu-header::before {
  background-color: rgba(255, 255, 255, 0.15);
}
.menu.bg-primary .menu-block::before {
  background-color: #c2c4ff;
}
.menu.bg-primary
  .menu-inner
  > .menu-item.open
  .menu-item.open
  > .menu-toggle::before {
  background-color: #8385ff;
}
.menu.bg-primary
  .menu-inner
  > .menu-item.open
  .menu-item.active
  > .menu-link::before {
  background-color: #fff;
}
.menu.bg-primary
  .menu-inner
  > .menu-item.open
  .menu-item.open
  > .menu-toggle::before,
.menu.bg-primary
  .menu-inner
  > .menu-item.open
  .menu-item.active
  > .menu-link::before {
  box-shadow: 0 0 0 2px #6d70ff;
}
.menu.bg-primary .ps__thumb-y,
.menu.bg-primary .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.5942917647) !important;
}
.footer.bg-primary {
  background-color: #076fb0 !important;
  color: #e0e1ff;
}
.footer.bg-primary .footer-link {
  color: #e0e1ff;
}
.footer.bg-primary .footer-link:hover,
.footer.bg-primary .footer-link:focus {
  color: #fff;
}
.footer.bg-primary .footer-link.disabled {
  color: #b0b2ff !important;
}
.footer.bg-primary .footer-text {
  color: #fff;
}
.footer.bg-primary .show > .footer-link,
.footer.bg-primary .active > .footer-link,
.footer.bg-primary .footer-link.show,
.footer.bg-primary .footer-link.active {
  color: #fff;
}
.footer.bg-primary hr {
  border-color: rgba(255, 255, 255, 0.15);
}
.bg-primary.toast,
.bg-primary.bs-toast {
  color: #fff;
  background-color: rgba(105, 108, 255, 0.85) !important;
  box-shadow: 0 0.25rem 1rem rgba(7 111 176, 0.4);
}
.bg-primary.toast .toast-header,
.bg-primary.bs-toast .toast-header {
  color: #fff;
}
.bg-primary.toast .toast-header .btn-close,
.bg-primary.bs-toast .toast-header .btn-close {
  background-color: #076fb0 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='ðŸŽ¨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='1' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  box-shadow: 0 0.1875rem 0.375rem 0 rgba(7 111 176, 0.4) !important;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  color: #076fb0;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  color: #076fb0;
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: #076fb0;
}
.svg-illustration svg {
  fill: #076fb0;
}
.jstree-default .jstree-wholerow-clicked,
.jstree-default .jstree-wholerow-hovered {
  background: #e9e9ff;
}
.jstree-default-dark .jstree-wholerow-hovered,
.jstree-default-dark .jstree-wholerow-clicked {
  background: #434d99;
}
html:not([dir="rtl"]) .border-primary,
html[dir="rtl"] .border-primary {
  border-color: #076fb0 !important;
}
a {
  color: #076fb0;
}
a:hover {
  color: #787bff;
}
.fill-primary {
  fill: #076fb0;
}
.noUi-primary .noUi-base .noUi-connect {
  background: #076fb0 !important;
}
.select2-container--default.select2-container--focus .select2-selection,
.select2-container--default.select2-container--open .select2-selection {
  border-color: #076fb0 !important;
}
.select2-primary
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background: rgba(105, 108, 255, 0.08) !important;
  color: #076fb0 !important;
}
.select2-primary
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice:hover {
  background: rgba(105, 108, 255, 0.16) !important;
}
table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  background-color: #076fb0;
  border: 2px solid #fff;
  box-shadow: 0 0 3px rgba(67, 89, 113, 0.8);
}
.bootstrap-select .dropdown-menu.inner a[aria-selected="true"] {
  background: rgba(105, 108, 255, 0.08) !important;
  color: #076fb0 !important;
}
.bootstrap-select .dropdown-toggle.show {
  border-color: #076fb0;
}
.datepicker table tr td.active,
.datepicker table tr td.active.highlighted,
.datepicker table tr td.active,
.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td.range-start,
.datepicker table tr td.range-end {
  background: #076fb0 !important;
  color: #fff !important;
  box-shadow: 0 0.125rem 0.25rem rgba(105, 108, 255, 0.45);
}
.datepicker table tr td.range,
.datepicker table tr td.range.highlighted,
.datepicker table tr td.range.today {
  color: #076fb0 !important;
  background: #e7e7ff !important;
}
.datepicker table tr td.range.focused,
.datepicker table tr td.range.highlighted.focused,
.datepicker table tr td.range.today.focused {
  background: #c8c8ff !important;
}
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.highlighted.disabled,
.datepicker table tr td.range.today.disabled {
  background: rgba(231, 231, 255, 0.5) !important;
  color: rgba(67, 89, 113, 0.5) !important;
}
.datepicker table tr td.today:not(.active) {
  border: 1px solid #076fb0;
}
.flatpickr-calendar .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}
.flatpickr-calendar .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}
.flatpickr-day.today {
  border-color: #076fb0;
}
.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange {
  color: #076fb0 !important;
  background: #e7e7ff !important;
  border-color: #e7e7ff !important;
}
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.week.selected {
  color: #fff !important;
  background: #076fb0 !important;
  border-color: #076fb0 !important;
  box-shadow: 0 0.125rem 0.25rem rgba(105, 108, 255, 0.45);
}
.daterangepicker table thead th select,
.daterangepicker table thead td select {
  background-color: transparent;
}
.daterangepicker td.active:not(.off) {
  background: #076fb0 !important;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(105, 108, 255, 0.45);
}
.daterangepicker .start-date:not(.end-date):not(.off),
.daterangepicker .end-date:not(.start-date):not(.off) {
  background-color: #076fb0;
  color: #fff;
  border: 0 !important;
}
.daterangepicker .start-date:not(.end-date):not(.off):hover,
.daterangepicker .end-date:not(.start-date):not(.off):hover {
  background-color: #076fb0 !important;
}
.daterangepicker .input-mini.active {
  border-color: #076fb0 !important;
}
.daterangepicker td.in-range:not(.start-date):not(.end-date):not(.off) {
  color: #076fb0 !important;
  background-color: #e7e7ff !important;
}
.ranges li.active {
  color: #fff !important;
  background-color: #076fb0 !important;
}
li.ui-timepicker-selected,
.ui-timepicker-list .ui-timepicker-selected:hover {
  color: #fff !important;
  background: #076fb0 !important;
}
.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar button:hover,
.ql-snow .ql-toolbar button:focus,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #076fb0 !important;
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #076fb0 !important;
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #076fb0 !important;
}
.tt-suggestion:active,
.tt-cursor {
  background: rgba(105, 108, 255, 0.08) !important;
  color: #076fb0 !important;
}
.dropzone.dz-drag-hover {
  border-color: #076fb0 !important;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active,
.swiper-pagination.swiper-pagination-progressbar
  .swiper-pagination-progressbar-fill {
  background: #076fb0 !important;
}
.sk-primary.sk-plane,
.sk-primary .sk-chase-dot:before,
.sk-primary .sk-bounce-dot,
.sk-primary .sk-wave-rect,
.sk-primary.sk-pulse,
.sk-primary .sk-swing-dot,
.sk-primary .sk-circle-dot:before,
.sk-primary .sk-circle-fade-dot:before,
.sk-primary .sk-grid-cube,
.sk-primary .sk-fold-cube:before {
  background-color: #076fb0;
}
.plyr input[type="range"]::-ms-fill-lower {
  background: #076fb0 !important;
}
.plyr input[type="range"]:active::-webkit-slider-thumb {
  background: #076fb0 !important;
}
.plyr input[type="range"]:active::-moz-range-thumb {
  background: #076fb0 !important;
}
.plyr input[type="range"]:active::-ms-thumb {
  background: #076fb0 !important;
}
.plyr--video .plyr__control.plyr__control--overlaid,
.plyr--video .plyr__controls button.tab-focus:focus,
.plyr--video .plyr__control[aria-expanded="true"],
.plyr--video .plyr__controls button:hover {
  background: #076fb0 !important;
  color: #fff !important;
}
.plyr--audio .plyr__controls button.tab-focus:focus,
.plyr--audio .plyr__control[aria-expanded="true"],
.plyr--audio .plyr__controls button:hover {
  background: #076fb0 !important;
  color: #fff !important;
}
.plyr__play-large {
  background: #076fb0 !important;
  color: #fff !important;
}
.plyr__progress--played,
.plyr__volume--display {
  color: #076fb0 !important;
}
.plyr--full-ui input[type="range"] {
  color: #076fb0 !important;
}
.plyr__menu__container
  .plyr__control[role="menuitemradio"][aria-checked="true"]::before {
  background: #076fb0 !important;
}
.fc .fc-event-primary:not(.fc-list-event) {
  background-color: #e7e7ff !important;
  color: #076fb0 !important;
}
.fc .fc-event-primary:not(.fc-list-event) {
  border-color: rgba(105, 108, 255, 0.15);
}
.fc .fc-event-primary.fc-list-event .fc-list-event-dot {
  border-color: #076fb0 !important;
}
.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button) {
  background-color: rgba(105, 108, 255, 0.08) !important;
  border: 0;
  color: #076fb0;
}
.fc
  .fc-button-primary:not(.fc-prev-button):not(.fc-next-button).fc-button-active,
.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):hover {
  background-color: #076fb0 !important;
  border-color: #076fb0 !important;
  color: #fff;
}
.fc .fc-button-primary:not(.fc-prev-button):not(.fc-next-button):hover {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(7 111 176, 0.4);
}
.swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step,
.swal2-progress-steps[class] .swal2-progress-step-line,
.swal2-progress-steps[class] .swal2-active-progress-step,
.swal2-progress-steps[class] .swal2-progress-step {
  background: #076fb0;
  color: #fff;
}
.swal2-progress-steps[class]
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step,
.swal2-progress-steps[class]
  .swal2-progress-step.swal2-active-progress-step
  ~ .swal2-progress-step-line {
  background: #e9e9ff;
}
.pcr-app .pcr-type.active,
.pcr-app .pcr-save {
  background: #076fb0 !important;
}
.icon-card.active {
  outline: 1px solid #076fb0;
}
.icon-card.active i,
.icon-card.active svg {
  color: #076fb0;
}
.shepherd-element[data-popper-placement="bottom"] .shepherd-arrow:before {
  background-color: #076fb0 !important;
  border-color: #076fb0;
}
.shepherd-element .shepherd-header {
  background: #076fb0 !important;
  color: #fff;
}
.shepherd-element .shepherd-header .shepherd-title,
.shepherd-element .shepherd-header .shepherd-cancel-icon {
  color: #fff !important;
}
.shepherd-element .shepherd-button:not(:disabled).btn-primary {
  color: #fff;
  background-color: #076fb0;
  border-color: #076fb0;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(7 111 176, 0.4);
}
.shepherd-element .shepherd-button:not(:disabled).btn-primary:hover {
  color: #fff;
  background-color: #0967a2;
  border-color: #076fb0;
  transform: translateY(-1px);
}
.btn-check:focus
  + .shepherd-element
  .shepherd-button:not(:disabled).btn-primary,
.shepherd-element .shepherd-button:not(:disabled).btn-primary:focus,
.shepherd-element .shepherd-button:not(:disabled).btn-primary.focus {
  color: #fff;
  background-color: #0967a2;
  border-color: #076fb0;
  transform: translateY(0);
  box-shadow: none;
}
.btn-check:checked
  + .shepherd-element
  .shepherd-button:not(:disabled).btn-primary,
.btn-check:active
  + .shepherd-element
  .shepherd-button:not(:disabled).btn-primary,
.shepherd-element .shepherd-button:not(:disabled).btn-primary:active,
.shepherd-element .shepherd-button:not(:disabled).btn-primary.active,
.show
  > .shepherd-element
  .shepherd-button:not(:disabled).btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #595cd9;
  border-color: #595cd9;
}
.btn-check:checked
  + .shepherd-element
  .shepherd-button:not(:disabled).btn-primary:focus,
.btn-check:active
  + .shepherd-element
  .shepherd-button:not(:disabled).btn-primary:focus,
.shepherd-element .shepherd-button:not(:disabled).btn-primary:active:focus,
.shepherd-element .shepherd-button:not(:disabled).btn-primary.active:focus,
.show
  > .shepherd-element
  .shepherd-button:not(:disabled).btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.shepherd-element .shepherd-button:not(:disabled).btn-primary.disabled,
.shepherd-element .shepherd-button:not(:disabled).btn-primary:disabled {
  box-shadow: none;
}
.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary {
  color: #8592a3;
  border-color: transparent;
  background: #ebeef0;
}
.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary:hover {
  border-color: transparent;
  background: #788393;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(133, 146, 163, 0.4);
  transform: translateY(-1px);
}
.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary:focus,
.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary.focus {
  color: #fff;
  background: #788393;
  box-shadow: none !important;
  transform: translateY(0);
}
.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary:active,
.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary.active,
.show
  > .shepherd-element
  .shepherd-button:not(:disabled).btn-label-secondary.dropdown-toggle {
  color: #fff;
  background-color: #717c8b;
  border-color: transparent;
}
.shepherd-element
  .shepherd-button:not(:disabled).btn-label-secondary:active:focus,
.shepherd-element
  .shepherd-button:not(:disabled).btn-label-secondary.active:focus,
.show
  > .shepherd-element
  .shepherd-button:not(:disabled).btn-label-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary.disabled,
.shepherd-element .shepherd-button:not(:disabled).btn-label-secondary:disabled {
  box-shadow: none;
}
.bs-stepper .step.active .bs-stepper-circle {
  background-color: #076fb0;
  color: #fff;
  box-shadow: 0 0.1875rem 0.375rem 0 rgba(7 111 176, 0.4);
}
.bs-stepper .step.active .bs-stepper-label {
  color: var(--bs-primary) !important;
}
.bs-stepper .step.active .bs-stepper-icon svg {
  fill: #076fb0 !important;
}
.bs-stepper .step.crossed .step-trigger .bs-stepper-circle {
  background-color: rgba(105, 108, 255, 0.16) !important;
  color: #076fb0 !important;
}
.bs-stepper .step.crossed .step-trigger .bs-stepper-icon svg {
  fill: #076fb0 !important;
}
.bs-stepper .step.crossed + .line svg {
  fill: #076fb0;
}
.bs-stepper .step.crossed + .line i {
  color: #076fb0;
}
.bs-stepper.wizard-icons .step.crossed .step-trigger .bs-stepper-label {
  color: #076fb0 !important;
}
.bs-stepper .step-trigger:focus {
  color: #076fb0;
}
.bs-stepper .step-trigger:focus svg {
  fill: #076fb0 !important;
}
.bg-navbar-theme {
  background-color: #fff !important;
  color: #697a8d;
}
.bg-navbar-theme .navbar-brand,
.bg-navbar-theme .navbar-brand a {
  color: #566a7f;
}
.bg-navbar-theme .navbar-brand:hover,
.bg-navbar-theme .navbar-brand:focus,
.bg-navbar-theme .navbar-brand a:hover,
.bg-navbar-theme .navbar-brand a:focus {
  color: #566a7f;
}
.bg-navbar-theme .navbar-search-wrapper .navbar-search-icon,
.bg-navbar-theme .navbar-search-wrapper .search-input {
  color: #697a8d;
}
.bg-navbar-theme .search-input-wrapper .search-input,
.bg-navbar-theme .search-input-wrapper .search-toggler {
  background-color: #fff !important;
  color: #697a8d;
}
.bg-navbar-theme .navbar-nav > .nav-link,
.bg-navbar-theme .navbar-nav > .nav-item > .nav-link,
.bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link {
  color: #697a8d;
}
.bg-navbar-theme .navbar-nav > .nav-link:hover,
.bg-navbar-theme .navbar-nav > .nav-link:focus,
.bg-navbar-theme .navbar-nav > .nav-item > .nav-link:hover,
.bg-navbar-theme .navbar-nav > .nav-item > .nav-link:focus,
.bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link:hover,
.bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link:focus {
  color: #566a7f;
}
.bg-navbar-theme .navbar-nav > .nav-link.disabled,
.bg-navbar-theme .navbar-nav > .nav-item > .nav-link.disabled,
.bg-navbar-theme .navbar-nav > .nav > .nav-item > .nav-link.disabled {
  color: #a5afbb !important;
}
.bg-navbar-theme .navbar-nav .show > .nav-link,
.bg-navbar-theme .navbar-nav .active > .nav-link,
.bg-navbar-theme .navbar-nav .nav-link.show,
.bg-navbar-theme .navbar-nav .nav-link.active {
  color: #566a7f;
}
.bg-navbar-theme .navbar-toggler {
  color: #697a8d;
  border-color: rgba(86, 106, 127, 0.075);
}
.bg-navbar-theme .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='14px' height='11px' viewBox='0 0 14 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M0,0 L14,0 L14,1.75 L0,1.75 L0,0 Z M0,4.375 L14,4.375 L14,6.125 L0,6.125 L0,4.375 Z M0,8.75 L14,8.75 L14,10.5 L0,10.5 L0,8.75 Z' id='path-1'%3E%3C/path%3E%3C/defs%3E%3Cg id='ðŸ’Ž-UI-Elements' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='12)-Navbar' transform='translate(-1174.000000, -1290.000000)'%3E%3Cg id='Group' transform='translate(1174.000000, 1288.000000)'%3E%3Cg id='Icon-Color' transform='translate(0.000000, 2.000000)'%3E%3Cuse fill='rgba(67, 89, 113, 0.5)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.1' fill='rgba(67, 89, 113, 0.5)' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.bg-navbar-theme .navbar-text {
  color: #697a8d;
}
.bg-navbar-theme .navbar-text a {
  color: #566a7f;
}
.bg-navbar-theme .navbar-text a:hover,
.bg-navbar-theme .navbar-text a:focus {
  color: #566a7f;
}
.bg-navbar-theme hr {
  border-color: rgba(86, 106, 127, 0.075);
}
.layout-navbar {
  background-color: rgba(255, 255, 255, 0.95) !important;
  -webkit-backdrop-filter: saturate(200%) blur(6px);
  backdrop-filter: saturate(200%) blur(6px);
}
.navbar-detached {
  box-shadow: 0 0 0.375rem 0.25rem rgba(161, 172, 184, 0.15);
}
.layout-navbar-fixed .layout-page:before {
  -webkit-backdrop-filter: saturate(200%) blur(10px);
  backdrop-filter: saturate(200%) blur(10px);
  background: rgba(244, 245, 251, 0.6);
}
.bg-menu-theme {
  background-color: #076fb0 !important;
  color: #c4cdd5;
}
.bg-menu-theme .menu-link,
.bg-menu-theme .menu-horizontal-prev,
.bg-menu-theme .menu-horizontal-next {
  color: #c4cdd5;
}
.bg-menu-theme .menu-link:hover,
.bg-menu-theme .menu-link:focus,
.bg-menu-theme .menu-horizontal-prev:hover,
.bg-menu-theme .menu-horizontal-prev:focus,
.bg-menu-theme .menu-horizontal-next:hover,
.bg-menu-theme .menu-horizontal-next:focus {
  color: #fff;
}
.bg-menu-theme .menu-link.active,
.bg-menu-theme .menu-horizontal-prev.active,
.bg-menu-theme .menu-horizontal-next.active {
  color: #fff;
}
.bg-menu-theme .menu-item.disabled .menu-link,
.bg-menu-theme .menu-horizontal-prev.disabled,
.bg-menu-theme .menu-horizontal-next.disabled {
  color: #84909c !important;
}
.bg-menu-theme .menu-item.active > .menu-link {
  color: #000;
}
.bg-menu-theme .menu-sub .menu-item.active > .menu-link {
  color: #fff;
}
.bg-menu-theme .menu-item.active > .menu-link:not(.menu-toggle) {
  background-color: #0967a2;
}
.bg-menu-theme.menu-horizontal
  .menu-sub
  > .menu-item.active
  > .menu-link:not(.menu-toggle) {
  background-color: #213242;
}
.bg-menu-theme.menu-horizontal
  .menu-inner
  .menu-item:not(.menu-item-closing)
  > .menu-sub,
.bg-menu-theme.menu-horizontal .menu-inner .menu-item.open > .menu-toggle {
  background: #234;
}
.bg-menu-theme
  .menu-inner
  > .menu-item.menu-item-closing
  .menu-item.open
  .menu-sub,
.bg-menu-theme
  .menu-inner
  > .menu-item.menu-item-closing
  .menu-item.open
  .menu-toggle {
  background: transparent;
  color: #c4cdd5;
}
.bg-menu-theme .menu-inner-shadow {
  background: linear-gradient(
    #233446 41%,
    rgba(35, 52, 70, 0.11) 95%,
    rgba(35, 52, 70, 0)
  );
}
.bg-menu-theme .menu-text {
  color: #fff;
}
.bg-menu-theme .menu-header {
  color: #9ca7b1;
}
.bg-menu-theme hr,
.bg-menu-theme .menu-divider,
.bg-menu-theme .menu-inner > .menu-item.open > .menu-sub::before {
  border-color: transparent !important;
}
.bg-menu-theme .menu-inner > .menu-header::before {
  background-color: transparent;
}
.bg-menu-theme .menu-block::before {
  background-color: #9ca7b1;
}
.bg-menu-theme
  .menu-inner
  > .menu-item.open
  .menu-item.open
  > .menu-toggle::before {
  background-color: #234;
}
.bg-menu-theme
  .menu-inner
  > .menu-item.open
  .menu-item.active
  > .menu-link::before {
  background-color: #fff;
}
.bg-menu-theme
  .menu-inner
  > .menu-item.open
  .menu-item.open
  > .menu-toggle::before,
.bg-menu-theme
  .menu-inner
  > .menu-item.open
  .menu-item.active
  > .menu-link::before {
  box-shadow: 0 0 0 2px #f5ecc6;
}
.bg-menu-theme .ps__thumb-y,
.bg-menu-theme .ps__rail-y.ps--clicking > .ps__thumb-y {
  background: rgba(255, 255, 255, 0.4768141176) !important;
}
.bg-menu-theme .menu-header {
  color: #a1acb8;
}
.bg-menu-theme .menu-header:before {
  background-color: #a1acb8 !important;
}
html:not(.layout-menu-collapsed)
  .bg-menu-theme
  .menu-inner
  .menu-item.open
  > .menu-link,
.layout-menu-hover.layout-menu-collapsed
  .bg-menu-theme
  .menu-inner
  .menu-item.open
  > .menu-link,
html:not(.layout-menu-collapsed)
  .bg-menu-theme
  .menu-inner
  .menu-item
  .menu-link:not(.active):hover,
.layout-menu-hover.layout-menu-collapsed
  .bg-menu-theme
  .menu-inner
  .menu-item
  .menu-link:not(.active):hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.bg-menu-theme
  .menu-inner
  .menu-sub
  > .menu-item.active
  > .menu-link.menu-toggle {
  background-color: rgba(255, 255, 255, 0.04);
}
.bg-menu-theme .menu-inner .menu-sub > .menu-item.active .menu-icon {
  color: #076fb0;
}
.bg-menu-theme .menu-inner > .menu-item.active > .menu-link {
  background-color: #ffde59 !important;
}
.bg-menu-theme .menu-inner > .menu-item.active:before {
  background: #ffde59;
}
.bg-menu-theme .menu-sub > .menu-item > .menu-link:before {
  background-color: #b4bdc6 !important;
}
.bg-menu-theme
  .menu-sub
  > .menu-item.active
  > .menu-link:not(.menu-toggle):before {
  background-color: #ffde59 !important;
  border: 3px solid #ffde59 !important;
}
.layout-horizontal .bg-menu-theme .menu-inner > .menu-item .menu-sub {
  box-shadow: 0 0.25rem 1rem rgba(35, 52, 70, 0.4);
}
.app-brand .layout-menu-toggle {
  background-color: #ffde59;
  border: 7px solid #f4f5fb;
}
.app-brand .layout-menu-toggle i {
  color: #000;
}
.bg-footer-theme {
  background-color: #f4f5fb !important;
  color: #697a8d;
}
.bg-footer-theme .footer-link {
  color: #697a8d;
}
.bg-footer-theme .footer-link:hover,
.bg-footer-theme .footer-link:focus {
  color: #566a7f;
}
.bg-footer-theme .footer-link.disabled {
  color: #a1abb9 !important;
}
.bg-footer-theme .footer-text {
  color: #566a7f;
}
.bg-footer-theme .show > .footer-link,
.bg-footer-theme .active > .footer-link,
.bg-footer-theme .footer-link.show,
.bg-footer-theme .footer-link.active {
  color: #566a7f;
}
.bg-footer-theme hr {
  border-color: rgba(86, 106, 127, 0.0768852941);
}
.layout-footer-fixed .content-footer {
  box-shadow: 0 0 0.375rem 0.25rem rgba(161, 172, 184, 0.15);
}
/*
* demo.css
* File include item demo only specific css only
******************************************************************************/

.menu .app-brand.demo {
  height: 64px;
  margin-top: 12px;
}

.app-brand-logo.demo svg {
  width: 22px;
  height: 38px;
}

.app-brand-text.demo {
  font-size: 2.45rem;
  letter-spacing: -0.5px;
}

/* ! For .layout-navbar-fixed added fix padding top tpo .layout-page */
/* Detached navbar */
.layout-navbar-fixed
  .layout-wrapper:not(.layout-horizontal):not(.layout-without-menu)
  .layout-page {
  padding-top: 76px !important;
}
/* Default navbar */
.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
  padding-top: 64px !important;
}

/* Navbar page z-index issue solution */
.content-wrapper .navbar {
  z-index: auto;
}

/*
* Content
******************************************************************************/

.demo-blocks > * {
  display: block !important;
}

.demo-inline-spacing > * {
  margin: 1rem 0.375rem 0 0 !important;
}

/* ? .demo-vertical-spacing class is used to have vertical margins between elements. To remove margin-top from the first-child, use .demo-only-element class with .demo-vertical-spacing class. For example, we have used this class in forms-input-groups.html file. */
.demo-vertical-spacing > * {
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-lg > * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-lg.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-xl > * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}
.demo-vertical-spacing-xl.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir="rtl"] .rtl-only {
  display: block !important;
}

/*
* Layout demo
******************************************************************************/

.layout-demo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 1rem;
}
.layout-demo-placeholder img {
  width: 900px;
}
.layout-demo-info {
  text-align: center;
  margin-top: 1rem;
}

.switch-off,
.switch-on {
  display: flex;
  align-items: center;
}
.switch .switch-toggle-slider i {
  top: inherit;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.timeline-edit-button {
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 22px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4caf50;
}

.user-table {
  width: 100%;
}
.prop-bg-color {
  background-color: #fff;
  border-radius: 7px;
}
.prop-card {
  padding: 20px;
  margin-bottom: 25px;
}
.prop-card div {
  padding: 5px 0px;
}
.prop-card .flex {
  display: flex;
  padding-left: 5px;
}
.prop-card p {
  margin: 0;
  padding: 0 5px;
}
.prop-card span {
  padding-left: 10px;
}
.prop-card .view-verify {
  display: flex;
  justify-content: space-between;
  color: #076fb0;
}

.prop-card .view-verify p {
  font-size: 17px;
}

.cursor-pointer {
  cursor: pointer;
}

.seller-prop .flex {
  display: flex;
  padding-left: 5px;
}

.widget-section {
  padding: 15px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 2px 7px rgb(167, 167, 167);
  border-radius: 5px;
}
.widget-section:hover {
  background-color: #e1edf5;
}
.waari-select-logo {
  padding-left: 35px;
}
.waari-select-logo .count {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: start;
}
.waari-select-logo img {
  width: 120px;
  padding-bottom: 15px;
}
.pack-Total .count {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  color: #000;
  font-weight: bold;
}
.pack-Total .package {
  font-size: 20px;
  color: #076fb0;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}
.total {
  font-size: 15px;
  font-weight: bold;
}
.package-img img {
  width: 70px;
  padding-top: 10px;
}

.text-red {
  color: red;
  font-size: 13px;
}

/* Property Details */

.is-project {
  display: flex;
}

.is-project .radio {
  padding-right: 20px;
}

.bhk {
  color: #076fb0;
}

.buy-rent-comm,
.comm-buy-rent {
  display: flex;
}

.comm-buy-rent {
  display: flex;
}

.buy,
.rent,
.comm {
  background-color: #e1edf5;
  padding: 10px 20px;
  cursor: pointer;
}

.buy:hover,
.rent:hover,
.comm:hover {
  background-color: #076fb0;
  color: #fff;
}

.buy-active,
.rent-active,
.comm-active {
  background-color: #076fb0 !important;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
}

.buy:focus,
.rent:focus,
.comm:focus {
  background-color: #076fb0;
  color: #fff;
}

.property-form-wrapper {
  margin: 20px 0;
}

.bhk {
  margin: 0;
}

#CountryID,
#StateID,
#CityID {
  width: 100%;
}
